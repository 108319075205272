
.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 500px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.content-table th {
  border: 1px solid white;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
  border: 0.5,mnv vb px solid #dddddd;
}

.content-table tr {
  width: 100%;
}

.content-table tbody tr {
  /* border-bottom: 1px solid #dddddd; */
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

