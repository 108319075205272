@import url(https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);

.content-table-deals {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 500px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table-deals thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.content-table-deals th {
  border: 1px solid white;
}

.content-table-deals th,
.content-table-deals td {
  padding: 12px 15px;
  text-align: center;
  border: 0.5,mnv vb px solid #dddddd;
}

.content-table-deals tr {
  width: 100%;
}

.content-table-deals tbody tr {
  /* border-bottom: 1px solid #dddddd; */
}

.content-table-deals tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table-deals tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table-deals tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.content-table-deals img {
  width: 20%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.content-table-deals td {
  padding: 0 1rem;
  max-width: 250px;
  max-height: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 500px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.content-table th {
  border: 1px solid white;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
  border: 0.5,mnv vb px solid #dddddd;
}

.content-table tr {
  width: 100%;
}

.content-table tbody tr {
  /* border-bottom: 1px solid #dddddd; */
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}


body::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 1rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #393e46;
  outline: 1px solid slategrey;
}

.react-date-picker__wrapper{
  padding: 0.3rem 1rem;
  border-radius: 3px;
  color: #393e46;
}

.r-dropdown, .Dropdown-placeholder, .react-date-picker__inputGroup__divider {
  color: #393e46;
}

.r-dropdown-links {
  width: 100%;
}

.Dropdown-menu {
  max-height: 140px;
}

.Dropdown-control {
  /* width: 230px; */
}

.r-dropdown-c {
  width: 40%;
}

