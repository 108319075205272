@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 1rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #393e46;
  outline: 1px solid slategrey;
}

.react-date-picker__wrapper{
  padding: 0.3rem 1rem;
  border-radius: 3px;
  color: #393e46;
}

.r-dropdown, .Dropdown-placeholder, .react-date-picker__inputGroup__divider {
  color: #393e46;
}

.r-dropdown-links {
  width: 100%;
}

.Dropdown-menu {
  max-height: 140px;
}

.Dropdown-control {
  /* width: 230px; */
}

.r-dropdown-c {
  width: 40%;
}
