
.content-table-deals {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 500px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table-deals thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.content-table-deals th {
  border: 1px solid white;
}

.content-table-deals th,
.content-table-deals td {
  padding: 12px 15px;
  text-align: center;
  border: 0.5,mnv vb px solid #dddddd;
}

.content-table-deals tr {
  width: 100%;
}

.content-table-deals tbody tr {
  /* border-bottom: 1px solid #dddddd; */
}

.content-table-deals tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table-deals tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table-deals tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.content-table-deals img {
  width: 20%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.content-table-deals td {
  padding: 0 1rem;
  max-width: 250px;
  max-height: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}